import { Center, Loader, Modal, Stack, Text, Title } from '@mantine/core';
import { useVersionsQuery } from './hooks/useVersionsQuery';
import { ExternalLink } from '../shared/ExternalLink';
import externalLinks from '@/utils/externalLinks';

export function AboutRapidModal({
  opened,
  close,
}: {
  opened: boolean;
  close: () => void;
}) {
  const { data, isLoading, isError } = useVersionsQuery();
  return (
    <Modal opened={opened} onClose={close} title='About RapidCompact'>
      {isLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Stack gap='sm'>
          <Stack gap='xs'>
            <Title size='h5' order={2}>
              Current Cloud Version
            </Title>
            <Text>
              {!isError && data?.appVersion ? data.appVersion : 'Not available'}
            </Text>
          </Stack>
          <Stack gap='xs'>
            <Title size='h5' order={2}>
              Current RapidCompact Core Version
            </Title>
            <Text>
              {!isError && data?.pbrVersion
                ? data.rpdxVersions
                : 'Not available'}
            </Text>
          </Stack>
          <Stack gap='xs'>
            <Title size='h5' order={2}>
              Current DCC Importer Version
            </Title>
            <Text>
              {!isError && data?.pbrVersion ? data.pbrVersion : 'Not available'}
            </Text>
          </Stack>
          <Stack gap='xs'>
            <Title size='h5' order={2}>
              RapidCompact Changelog
            </Title>
            <ExternalLink target='_blank' href={externalLinks.changelog()}>
              You can find the current changelog here
            </ExternalLink>
          </Stack>
        </Stack>
      )}
    </Modal>
  );
}
