import { Avatar, Group, Menu, Text } from '@mantine/core';
import { signOut, useSession } from 'next-auth/react';
import { ApiUser } from '@/types/user';
import { clearCookies } from '@/app/proxy-server-cookie';
import { urlHelper } from '@/utils';
import { AccountPageMenuItem } from '@/app/components/navbar/AccountPageMenuItem';
import externalLinks from '@/utils/externalLinks';
import { useDisclosure } from '@mantine/hooks';
import { AboutRapidModal } from './AboutRapidModal';
import { useCreateSupportTicket } from '../../contexts/CreateSupportTicketContext';

export function AccountPagesMenu() {
  const { data: session } = useSession();
  const [aboutRapidModalOpened, { open: aboutRapidModalOpen, close: aboutRapidModalClose }] = useDisclosure(false);
  const { open } = useCreateSupportTicket();

  if (!session?.user) {
    return null;
  }

  const customSignOut = async () => {
    await signOut({ redirect: false });
    clearCookies();
  };

  return (
    <>
      <Group gap='xs' wrap='nowrap'>
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <Group gap='xs' style={{ cursor: 'pointer' }}>
              <Avatar component='a' color='gray.6' style={{ cursor: 'pointer' }} src={session.user.avatar}>
                {getInitials(session.user)?.toLocaleUpperCase()}
              </Avatar>
              <Text size='sm' c='gray.6'>
                {session.user.email}
              </Text>
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <AccountPageMenuItem link={urlHelper.settings()}>Profile & Settings</AccountPageMenuItem>
            <AccountPageMenuItem link={urlHelper.usageStatistics()}>Usage Statistics</AccountPageMenuItem>
            {session?.user?.plan?.details?.id !== 1 && (
              <>
                <AccountPageMenuItem link={urlHelper.cli()}>CLI</AccountPageMenuItem>
                <AccountPageMenuItem link={urlHelper.webhooks()}>Webhooks</AccountPageMenuItem>
                <AccountPageMenuItem link={urlHelper.apiTokens()}>API Tokens</AccountPageMenuItem>
              </>
            )}
            <AccountPageMenuItem target='_blank' link={externalLinks.serviceStatus()}>
              Service Status
            </AccountPageMenuItem>
            <AccountPageMenuItem target='_blank' link={externalLinks.onlineDocumentation()}>
              Online Documentation
            </AccountPageMenuItem>
            <Menu.Item onClick={aboutRapidModalOpen}>About RapidCompact</Menu.Item>
            <Menu.Item onClick={() => open()}>Create a Support Ticket</Menu.Item>
            <AccountPageMenuItem link={externalLinks.legacyFrontend()} target='_self'>
              Switch to old UI
            </AccountPageMenuItem>
            <Menu.Item onClick={customSignOut}>Log out</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <AboutRapidModal opened={aboutRapidModalOpened} close={aboutRapidModalClose} />
    </>
  );
}

function getInitials(user: ApiUser) {
  const email = user.email.split('@')?.[0].replace(/\./g, ' ');
  return user.address.first_name ? `${user.address.first_name[0]}${user.address.last_name[0]}` : email?.substring(0, 2);
}
