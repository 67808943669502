const urlHelper = {
  home: () => '/',
  login: () => '/login',
  register: () => '/signup',
  verifyAccount: () => '/verify_account',
  verifyEmail: () => '/verify_mail',
  assets: () => '/assets',
  pipelines: () => '/pipelines',
  workflows: () => '/workflows',
  settings: () => '/settings',
  embeded: (rapidId?: number) => {
    if (rapidId === undefined) {
      return '/embeded';
    }
    return `/embeded/rapid/${rapidId}`;
  },
  published: () => '/published',
  cli: () => '/cli',
  webhooks: () => '/webhooks',
  usageStatistics: () => '/usageStatistics',
  forgotPassword: () => '/forgotPassword',
  apiTokens: () => '/api_tokens',
  plan: {
    choosePlan: () => '/choose_plan',
    paymentInfo: () => '/choose_plan/payment_info',
    summary: () => '/choose_plan/payment_info/summary',
  },
};

export default urlHelper;
