'use client';
import RapidLogo from '@/icons/rapid-logo.svg';
import styles from './styles.module.css';
import { NavbarItems } from '@/app/components/navbar/NavbarItems';
import { useRestrictedPath } from '@/shared/useRestrictedPath';
import { Box, Container, Group } from '@mantine/core';
import { AccountPagesMenu } from '@/app/components/navbar/AccountPagesMenu';
import Link from "next/link";
import {urlHelper} from "@/utils";

export default function Navbar() {
  const { isRestrictedPath } = useRestrictedPath();

  return (
    <nav>
      <Box
        w='100%'
        style={{
          borderBottom: '1px solid #e5e5e5',
        }}
        bg='white'
      >
        <Container fluid maw={1440}>
          <Group
            justify='space-between'
            align='center'
            px='xs'
            h='60'
            w='100%'
            wrap='nowrap'
          >
            <div className='flex justify-start gap-4'>
              <Link href={isRestrictedPath ? urlHelper.assets() : urlHelper.home()}>
                <RapidLogo
                  className={`${styles.logo} mt-2`}
                  alt='RapidCompact logo'
                  width={50}
                  height={45}
                />
              </Link>
              {isRestrictedPath && (
                <div className='ml-2 flex'>
                  <NavbarItems />
                </div>
              )}
            </div>
            <AccountPagesMenu />
          </Group>
        </Container>
      </Box>
    </nav>
  );
}
