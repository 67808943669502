import Link from 'next/link';
import { Menu } from '@mantine/core';
import { PropsWithChildren } from 'react';

export function AccountPageMenuItem({
  link,
  children,
  target,
}: {
  link: string;
  target?: string;
} & PropsWithChildren) {
  return (
    <Menu.Item>
      <Link target={target} style={{ display: 'block' }} href={link}>
        {children}
      </Link>
    </Menu.Item>
  );
}
